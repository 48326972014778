import {useMemo} from 'react';

import {graphqlClient} from './graphql-client';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

export const addApolloState = (client, pageProps) => {
    if (pageProps?.props) {
        // eslint-disable-next-line no-param-reassign
        pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
    }

    return pageProps;
};

export const useApollo = ({pageProps}) => {
    const initalState = pageProps?.[APOLLO_STATE_PROP_NAME];
    const client = useMemo(() => graphqlClient(initalState), [initalState]);

    return client;
};
