import {useRouter} from 'next/router';

import {useSubNav} from '../hooks/use-sub-nav';
import {useCustomerContext} from '../contexts/customer-context';

export const useSubNavProps = () => {
    const router = useRouter();
    const {data, loading} = useSubNav();
    const {isNationalCustomer} = useCustomerContext();

    if (loading || !data) {
        return null;
    }

    if (data.overrideUrls && data.overrideUrls.some((url) => url && router.asPath.includes(url))) {
        return null;
    }

    const filteredLinks = data.links.filter(({locationType}) => locationType === 'Both' ||
        locationType === (isNationalCustomer ? 'National' : 'Local'));
    const items = filteredLinks.map((link) => ({
        label: link.text,
        url: link.href
    }));

    return {
        'aria-label': 'Deals Sub Navigation',
        items
    };
};
