
import gql from 'graphql-tag';

import colorFragment from './ColorFragment';
import displayFlagsFragment from './DisplayFlagsFragment';

const cmsTextBlock = gql`
    ${colorFragment}
    ${displayFlagsFragment}
    fragment ITextBlock on CmsTextBlock {
        _type
        _key
        maxColumns
        text: textRaw
        textAlignment
        verticalDivider
        horizontalDivider
        backgroundColor {
            ...IColor
        }
        textColor {
            ...IColor
        }
        displayFlags {
            ...IDisplayFlags
        }
    }
`;

export default cmsTextBlock;
