import React, {Dispatch, SetStateAction, useEffect} from 'react';
import styled from 'styled-components';

const StyledIframe = styled.iframe`
    display: none;
`;

interface IAuthIframe {
    wantsCustomerContext: boolean;
    isAuthenticated: boolean;
    customerLoadAttempted: boolean;
    listenerReady: boolean;
}

const AuthIframe = ({
    wantsCustomerContext,
    isAuthenticated,
    customerLoadAttempted,
    listenerReady
}: IAuthIframe) => {
    if (wantsCustomerContext && !isAuthenticated && customerLoadAttempted && listenerReady) {
        return <StyledIframe
            aria-hidden
            src="/deals/keycloak-iframe"
            title="Hidden Auth IFrame"
        // eslint-disable-next-line react/jsx-closing-bracket-location
        />;
    }

    return null;
};

interface IUseAuthIframeMessagingProps {
    wantsCustomerContext: boolean;
    isAuthenticated: boolean;
    customerLoadAttempted: boolean;
    setCustomerLoadAttempted: Dispatch<SetStateAction<boolean>>;
    setListenerReady: Dispatch<SetStateAction<boolean>>;

}

export const useAuthIframeMessaging = ({
    wantsCustomerContext,
    isAuthenticated,
    customerLoadAttempted,
    setCustomerLoadAttempted,
    setListenerReady
}: IUseAuthIframeMessagingProps) => {
    useEffect(() => {
        const handleMessage = (event: any) => {
            if (event.data === 'auth-checked') {
                window.removeEventListener('message', handleMessage);
                setListenerReady(false);
                setCustomerLoadAttempted(false);
            }
        };

        if (wantsCustomerContext && !isAuthenticated) {
            window.addEventListener('message', handleMessage);
            setListenerReady(true);
        }

        return () => {
            window.removeEventListener('message', handleMessage);
            setListenerReady(false);
        };
    }, [wantsCustomerContext, isAuthenticated, customerLoadAttempted, setCustomerLoadAttempted, setListenerReady]);
};

export default AuthIframe;
