import {useEffect, useState} from 'react';
import * as Cookies from 'js-cookie';
import {NetworkStatus, useMutation, useQuery} from '@apollo/client';

import {THREE_LEGGED_AUTH_TYPE} from '../enums/auth-types';
import {useCustomerContext} from '../contexts/customer-context';
import {CreateCart, CreateCartVariables} from '../autogen/CreateCart';
import {GetAislesOnlineCart, GetAislesOnlineCart_carts} from '../autogen/GetAislesOnlineCart';
import {createCartMutation} from '../../src/graphql/mutations/cart-mutations';
import {GetMadeToOrderCart_madeToOrderCart} from '../autogen/GetMadeToOrderCart';
import {getAislesOnlineCart, getMadeToOrderCart} from '../../src/graphql/queries/cart-queries';

export interface IUseAislesOnlineCart {
    data: GetAislesOnlineCart_carts | null;
    loading: boolean;
    refetch: () => void;
}

interface IUseMadeToOrderCart {
    data: GetMadeToOrderCart_madeToOrderCart | null;
    loading: boolean;
}

const useCreateCartIfNeeded = (customerLoading, customerId, data, error, loading, networkStatus, refetch, createCartIfNeeded) => {
    const [creatingCart, setCreatingCart] = useState(false);
    const [createCart] = useMutation<CreateCart, CreateCartVariables>(createCartMutation, {
        onCompleted: () => {
            refetch();
        }
    });

    const isRefetching = networkStatus === NetworkStatus.refetch;
    const hasActiveCart = Boolean(data?.carts?.length);
    const shouldCreateNewCart =
        !customerLoading &&
        customerId &&
        !creatingCart &&
        !isRefetching &&
        !loading &&
        !error &&
        !hasActiveCart &&
        createCartIfNeeded;

    useEffect(() => {
        const createCartIfNecessary = async () => {
            if (shouldCreateNewCart) {
                setCreatingCart(true);

                await createCart({
                    variables: {
                        createCartInput: {
                            customerId: Number(customerId)
                        }
                    }
                });

                setCreatingCart(false);
            }
        };

        createCartIfNecessary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldCreateNewCart]);
};

export const useAislesOnlineCart = (createCartIfNeeded = false): IUseAislesOnlineCart => {
    const {customer, loading: customerLoading} = useCustomerContext();
    const customerId = customer?.customerId;
    const {data, loading, error, networkStatus, refetch} = useQuery<GetAislesOnlineCart>(getAislesOnlineCart, {
        fetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true,
        skip: !customerId || customerLoading,
        ssr: false,
        variables: {
            customerId: Number(customerId)
        }
    });

    useCreateCartIfNeeded(customerLoading, customerId, data, error, loading, networkStatus, refetch, createCartIfNeeded);

    return {
        data: data?.carts?.[0] || null,
        loading: loading || customerLoading,
        refetch
    };
};

export const useMadeToOrderCart = (): IUseMadeToOrderCart => {
    const cartId = Cookies.get('ecomCartUUID');
    const {data, loading} = useQuery(getMadeToOrderCart, {
        fetchPolicy: 'cache-first',
        skip: !cartId,
        ssr: false,
        variables: {
            authType: THREE_LEGGED_AUTH_TYPE,
            cartId
        }
    });

    return {
        data: data?.madeToOrderCart || null,
        loading
    };
};

