import React, {useContext, useEffect, useMemo, useState} from 'react';

import {getWeeklyAdCookie} from '../utils/weekly-ad-cookie';
import {getWeeklyAdPreviewDateCookie} from '../utils/weekly-ad-preview-date-cookie';

interface ICookieContext {
    cookieStoreId: string | undefined
    weeklyAdPreviewDate: string | undefined
    loading: boolean
}

const CookieContext = React.createContext<ICookieContext | undefined>(undefined);

CookieContext.displayName = 'CookieContext';
const {Provider} = CookieContext;

export const useCookies = (): ICookieContext => {
    const context = useContext(CookieContext);

    if (!context) {
        throw new Error(
            'Unable to create store cookie context, please ensure your component is within the CookieProvider'
        );
    }

    return context;
};

export const CookieProvider = ({
    children
}: any) => {
    const [loading, setLoading] = useState(true);
    const [cookieStoreId, setCookieStoreId] = useState(undefined);
    const [weeklyAdPreviewDate, setWeeklyAdPreviewDate] = useState(undefined);
    const cookieStateMemo = useMemo(() => ({
        cookieStoreId,
        loading,
        weeklyAdPreviewDate
    }), [cookieStoreId, loading, weeklyAdPreviewDate]);

    useEffect(() => {
        const loadCookies = async () => {
            const [cookieStore, weeklyAdPreviewDateCookie] = await Promise.all([getWeeklyAdCookie(), getWeeklyAdPreviewDateCookie()]);

            setCookieStoreId(cookieStore);
            setWeeklyAdPreviewDate(weeklyAdPreviewDateCookie);
            setLoading(false);
        };

        loadCookies();
    }, []);

    return (
        <Provider
            value={cookieStateMemo}
        >
            {children}
        </Provider>
    );
};
