import gql from 'graphql-tag';

const citrusSlotIdFragment = gql`
    fragment ICmsSlotId on CmsSlotId {
        _type
        _key
        type
        slotId
    }
`;

const citrusBannerImageSlotFragment = gql`
    ${citrusSlotIdFragment}

    fragment ICmsBannerImageSlot on CmsBannerImageSlot {
        _type
        _key
        contentType
        desktopSlot {
            ...ICmsSlotId
        }
        mobileSlot {
            ...ICmsSlotId
        }
    }
`;

export default citrusBannerImageSlotFragment;
