import getConfig from 'next/config';
import {NextRouter} from 'next/router';
import {AddToListTypes} from '@hy-vee/react-web-and-mobile-ui-components';

import ID from '../types/Id';

import {getLoginUrl, getLogoutUrl} from './redirect-utils';

export const navigate = (url:string, router?: NextRouter) => {
    if (router) {
        router.push(url);

        return;
    }

    window.location.assign(url);
};

export const getSelectedStore = (isAuthenticated: any, store: any) => {
    if (isAuthenticated) {
        return {
            id: null,
            name: store.nickname || store.name,
            storeCode: store.storeId
        };
    }

    return {};
};

export const login = (path: any) => {
    const pathWithRedirect = getLoginUrl(path);

    window.location.assign(pathWithRedirect);
};

export const logout = (path: any) => {
    const pathWithRedirect = getLogoutUrl(path);

    window.location.assign(pathWithRedirect);
};

export const getAddToListUrl = (
    id?: ID,
    type?: AddToListTypes,
    text?: string,
    optionalDealProductId: ID = 0
): string => {
    const {hyveeBaseUrl} = getConfig().publicRuntimeConfig;
    const listUrl = `${hyveeBaseUrl}/aisles-online/lists/modal/add-to-lists`;

    if (type === AddToListTypes.PRODUCT) {
        return `${listUrl}/product/${id}${optionalDealProductId ? `?dealId=${optionalDealProductId}` : ''}`;
    }

    if (type === AddToListTypes.DEAL) {
        return `${listUrl}/deal/${id}`;
    }

    if (type === AddToListTypes.ITEM) {
        return `${listUrl}/item/${id}`;
    }

    if (text) {
        const textTolistUrl = `${hyveeBaseUrl}/aisles-online/lists/modal/add-text-to-list`;

        return `${textTolistUrl}?listItemsToAdd=${encodeURIComponent(`["${text}"]`)}`;
    }

    return listUrl;
};

export const getShippingPreferenceDrawerUrl = (): string => {
    const {hyveeBaseUrl} = getConfig().publicRuntimeConfig;

    return `${hyveeBaseUrl as string}/aisles-online/shipping-preference-drawer`;
};

export const getCouponDetailsUrl = (couponId: string | number): string => {
    const {hyveeBaseUrl} = getConfig().publicRuntimeConfig;

    return `${hyveeBaseUrl}/aisles-online/coupons/${couponId}`;
};

export const getReservationStepperUrl = (): string => {
    const {hyveeBaseUrl} = getConfig().publicRuntimeConfig;

    return `${hyveeBaseUrl}/aisles-online/reservation-stepper`;
};

export const getReservationDrawerUrl = (): string => {
    const {hyveeBaseUrl} = getConfig().publicRuntimeConfig;

    return `${hyveeBaseUrl}/aisles-online/reservation-drawer`;
};

export const getDealSubscribeUrl = () => {
    const {hyveeBaseUrl} = getConfig().publicRuntimeConfig;

    window.location.assign(`${hyveeBaseUrl}/account/subscribe.aspx`);
};
