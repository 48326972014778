import {getSelectedAdStore, setSelectedAdStore} from '../services/selected-ad-store-service';

import {isServer} from './window';

export const getWeeklyAdCookie = () => {
    try {
        if (!isServer()) {
            return getSelectedAdStore();
        }
    } catch {}

    return undefined;
};

export const setWeeklyAdCookie = async (storeId: any) => {
    try {
        if (!isServer()) {
            await setSelectedAdStore(storeId);
        }
    } catch {}

    return storeId;
};
