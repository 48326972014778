import gql from 'graphql-tag';

const ImageButtonFragment = gql`
    fragment ImageButton on CmsImageButton {
        _key
        _type
        buttonText
        buttonLink
        buttonImage {
            alt
            asset {
                url
            }
        }
    }
`;

const ImageButtonsFragment = gql`
    ${ImageButtonFragment}

    fragment ImageButtons on CmsImageButtons {
        _key
        _type
        buttons {
            ...ImageButton
        }
        titleText
        moreButtonText
        moreButtonLink
        moreButtonTextColor {
            hex
        }
        moreButtonBackgroundColor {
            hex
        }
    }
`;

export default ImageButtonsFragment;
