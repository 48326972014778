import gql from 'graphql-tag';

export const getStore = gql`
  query GetStore($storesWhere: storeInput)  {
    stores(where: $storesWhere) {
        storeId
        name
        phone
        address
        city
        state
        zip
        nickname
        legacyStoreId
      }
  }
`;
