import {useQuery} from '@apollo/client';

import {TWO_LEGGED_AUTH_TYPE} from '../enums/auth-types';
import {getSubNav} from '../../src/graphql/queries/sanity-queries';
import {GetSubNav, GetSubNav_allCmsSharedSubNavigationConfig} from '../autogen/GetSubNav';

export interface IUseSubNav {
    data: GetSubNav_allCmsSharedSubNavigationConfig
    loading: boolean
}

export const useSubNav = (): IUseSubNav => {
    const {data, loading} = useQuery<GetSubNav>(getSubNav, {
        ssr: false,
        variables: {
            authType: TWO_LEGGED_AUTH_TYPE
        }
    });

    return {
        data: data?.allCmsSharedSubNavigationConfig?.[0] || null,
        loading
    };
};
