import {OK} from 'http-status';

import fetchService from './fetch-service';

const getWeeklyAdPreviewDatePrivate = async (): Promise<any> => {
    const response = await fetchService('/deals/api/weekly-ad-preview-date');

    if (response.status !== OK) {
        return undefined;
    }

    const data = await response.json();

    return data?.givenDate;
};

let loadingPromise: Promise<any>;

export const getWeeklyAdPreviewDate = (): Promise<any> => {
    if (!loadingPromise) {
        loadingPromise = getWeeklyAdPreviewDatePrivate();

        loadingPromise.finally(() => {
            loadingPromise = undefined;
        });
    }

    return loadingPromise;
};

export const deleteWeeklyAdPreviewDate = async (): Promise<void> => {
    await fetchService('/deals/api/weekly-ad-preview-date', {
        method: 'DELETE'
    });
};
