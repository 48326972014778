import React, {FC} from 'react';

import {useAislesOnlineCart} from '../../../hooks/use-cart';
import {getReservationDrawerUrl, getReservationStepperUrl} from '../../../utils/navigation-helpers';
import IFrameModal from '../iframe-modal';
import {IModal} from '../types';
import {useFeatureToggle} from '../../../contexts/feature-toggle';
import {AO_RESERVATION_DRAWER} from '../../../enums/feature-toggle-names';

const ReservationStepperModal: FC<IModal> = ({isVisible, onClose}) => {
    const {refetch: refetchCart} = useAislesOnlineCart();
    const {featureEnabled} = useFeatureToggle();
    const isReservationDrawerEnabled = featureEnabled(AO_RESERVATION_DRAWER);
    const iframeUrl = isReservationDrawerEnabled ? getReservationDrawerUrl() : getReservationStepperUrl();

    const handleMessage = (message: string) => {
        if (message === 'cancel-reservation-stepper') {
            refetchCart();

            onClose();
        }
    };

    return (
        <IFrameModal
            ariaLabel="Store Selection"
            onMessage={handleMessage}
            showModal={isVisible}
            url={iframeUrl}
        />
    );
};

export default ReservationStepperModal;
