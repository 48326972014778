import gql from 'graphql-tag';

export const storeAdFragment = gql`
    fragment IStoreAd on storeAd {
        ad {
            description
        }
        storeAdId
        adId
        storeId
        imageUri
    }
`;

export const localAdQuery = gql`
query LocalAdsQuery ($storeId: Int!) {
        localAds (storeId: $storeId) {
            localAds {
                localAdId
                storeCode
                title
                startDate
                endDate
                displayDate
                version
                pngFilePath
                typeID
                pdfFolderName
                isLocalAd
                imageUri
                images
            }
        }
    }
`;

export const storeAdDepartments = gql`
    query GetDealDepartments($storeId: Int!){
        dealsDepartments(storeId: $storeId) {
            id: departmentId
            name: departmentName,
            count: groupCount
        }
    }
`;

export const storeAdsQuery = gql`
    ${storeAdFragment}
    
    query GetStoreAds($storeId: Int!) {
        storeAds(storeId: $storeId) {
            storeAds {
                ...IStoreAd
            }
          }
    }
`;

export const storeAdsQueryWithDate = gql`
    ${storeAdFragment}

    query GetStoreAdsWithDate($storeId: Int!, $givenDate: String) {
        storeAds(storeId: $storeId, givenDate: $givenDate) {
            storeAds {
                ...IStoreAd
                beginDate
                endDate
            }
        }
    }
`;
