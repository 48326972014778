import React, {createContext, useState, useContext, useMemo, useEffect, FC} from 'react';
import {AddToListTypes} from '@hy-vee/react-web-and-mobile-ui-components';

import ID from '../../types/Id';
import {useStore} from '../../hooks/use-store';
import {isServerSide} from '../../utils/env';
import {useCustomerContext} from '../../contexts/customer-context';

import {ShippingPreferenceModal} from './ShippingPreferenceModal';
import CouponModal from './coupon-modal';
import AddToList from './add-to-list-modal';
import StoreSelectionModal from './store-selection';
import CustomerTypeModal from './customer-type-modal';

type CouponId = string | number | null;

export interface IModalContext {
    addToList: {
        hide: () => void;
        isVisible: boolean;
        addToList: (type: AddToListTypes, id: ID, optionalDealProductId?: ID) => void
        addText: (text: string) => void;
    };
    coupon: {
        hide: () => void;
        isVisible: boolean;
        show: (couponId: CouponId) => void;
    };
    storeSelector: {
        hide: () => void;
        isVisible: boolean;
        show: () => void;
    };
    shippingPreferenceDrawer: {
        hide: () => void;
        isVisible: boolean;
        show: () => void;
    };
}

const ModalContext = createContext<IModalContext | undefined>(undefined);

export const useModalContext = () => {
    const context = useContext(ModalContext);

    if (!context) {
        throw new Error(
            'Unable to create modal context, please ensure your component is within the ModalProvider'
        );
    }

    return context;
};

const setVisibilityWrapper = <T, >(update: T, current: T, set: (val: T) => void): void => {
    if (update !== current) {
        set(update);
    }
};

interface IModalProviderProps {
    forceStoreSelection: boolean;
}

interface IListState {
    id?: ID
    optionalDealProductId?: ID
    text?: string
    type?: AddToListTypes
}

const ModalProvider:FC<IModalProviderProps> = ({
    children,
    forceStoreSelection
}) => {
    const [isStoreSelectorVisible, setIsStoreSelectorVisible] = useState(false);
    const [couponId, setCouponId] = useState<CouponId>(null);
    const [addToListState, setAddToListState] = useState<IListState | null>(null);
    const [isShippingPreferenceDrawerVisible, setIsShippingPreferenceDrawerVisible] = useState(false);

    const {data: store, loading: storeLoading} = useStore();
    const {loading: customerLoading, setIsNationalCustomer, isNationalCustomer} = useCustomerContext();
    const serverside = isServerSide();

    useEffect(() => {
        if (forceStoreSelection) {
            setIsStoreSelectorVisible(!serverside && !customerLoading && !storeLoading && !store);
        }
    }, [store, storeLoading, customerLoading, serverside, forceStoreSelection]);

    const value = useMemo(() => ({
        addToList: {
            addText: (text: string) => setAddToListState({
                text
            }),
            addToList: (type: AddToListTypes, id: ID, optionalDealProductId?: ID) => setAddToListState({
                id,
                optionalDealProductId,
                type
            }),
            hide: () => setAddToListState(null),
            isVisible: Boolean(addToListState)
        },
        coupon: {
            hide: () => setCouponId(null),
            isVisible: Boolean(couponId),
            show: (id: CouponId) => setVisibilityWrapper(id, couponId, setCouponId)
        },
        shippingPreferenceDrawer: {
            hide: () =>
                setVisibilityWrapper(
                    false,
                    isShippingPreferenceDrawerVisible,
                    setIsShippingPreferenceDrawerVisible
                ),
            isVisible: isShippingPreferenceDrawerVisible,
            show: () =>
                setVisibilityWrapper(true, isShippingPreferenceDrawerVisible, setIsShippingPreferenceDrawerVisible)
        },
        storeSelector: {
            hide: () => setVisibilityWrapper(false, isStoreSelectorVisible, setIsStoreSelectorVisible),
            isVisible: isStoreSelectorVisible,
            show: () => setVisibilityWrapper(true, isStoreSelectorVisible, setIsStoreSelectorVisible)
        }
    }), [isStoreSelectorVisible, couponId, addToListState, isShippingPreferenceDrawerVisible]);

    return (
        <ModalContext.Provider value={value}>
            {children}
            <CustomerTypeModal
                isNationalCustomer={isNationalCustomer}
                setIsNationalCustomer={setIsNationalCustomer}
            />
            <StoreSelectionModal
                isVisible={isStoreSelectorVisible}
                onClose={() => setIsStoreSelectorVisible(false)}
            />
            <CouponModal
                couponId={couponId}
                isVisible={Boolean(couponId)}
                onClose={() => setCouponId(null)}
            />
            <AddToList
                id={addToListState?.id}
                isVisible={Boolean(addToListState)}
                onClose={() => setAddToListState(null)}
                optionalDealProductId={addToListState?.optionalDealProductId}
                text={addToListState?.text}
                type={addToListState?.type}
            />
            <ShippingPreferenceModal
                isVisible={isShippingPreferenceDrawerVisible}
                onClose={(): void => setIsShippingPreferenceDrawerVisible(false)}
            />

        </ModalContext.Provider>
    );
};

export default ModalProvider;
