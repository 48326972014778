import React, {FC} from 'react';
import {Select} from '@hy-vee/design-system';

interface ISelectOption {
    label: string;
    value: string;
    disabled?: boolean;
}

interface ISelectProps {
    disabled?: boolean;
    id: string;
    label: string;
    options: ISelectOption[];
    value?: string;
    overrideErrorAlertRole?: boolean;
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
    styles: {
        menuPortal: (styles: any, state: any) => React.CSSProperties
    }

}

export const disabledSelectOption = {
    disabled: true,
    label: 'Select...',
    value: ''
};

const SelectWithPlaceholder: FC<ISelectProps> = ({options, ...props}) =>
    <Select
        {...props}
        options={[disabledSelectOption, ...options]}
    />;

export default SelectWithPlaceholder;
