import gql from 'graphql-tag';

const RgbColorFragment = gql`
    fragment RgbColorFragment on Color {
        rgb {
            r
            g
            b
            a
        }
    }
`;

export default RgbColorFragment;
