import getConfig from 'next/config';
import queryString from 'query-string';

const LOGIN_PATH = '/deals/login';
const LOGOUT_PATH = '/deals/logout';

const returnUrl = (pathWithQuery) =>
    queryString.stringify({
        redirect: pathWithQuery
    });

export const getLoginUrl = (path) => `${LOGIN_PATH}?${returnUrl(path)}`;

export const getLogoutUrl = (path) => `${LOGOUT_PATH}?${returnUrl(path)}`;

export const getSignUpUrl = () => {
    const {hyveeBaseUrl} = getConfig().publicRuntimeConfig;

    return `${hyveeBaseUrl}/sign-up?returl=/deals/coupons.aspx`;
};
