import React, {FC} from 'react';
import styled from 'styled-components';
import {Modal} from '@hy-vee/web-core';
import {Button} from '@hy-vee/design-system';
import {useRouter} from 'next/router';

import {useStore} from '../../hooks/use-store';
import {useFeatureToggle} from '../../contexts/feature-toggle';
import {CUSTOMER_TYPE_MODAL} from '../../enums/feature-toggle-names';

const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    
    div {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        background-color: #F5F5F5;
      
        img {
          width: 100%;
        }
    
        h2 {
          font-size: var(--font-size--24);
          line-height: 27.6px;
          margin: var(--spacing--md) var(--spacing--md) 0;
        }
      
        h2 strong {
          display: inline-block;  
          font-weight: var(--font-weight--bold);
        }
      
        p {
          font-size: var(--font-size--14);
          line-height: 21px;
          margin: 0 var(--spacing--md);
        }
      
        button {
          margin: var(--spacing--md);
        }
    }

    div:last-child {
      margin: 0;
    }
  
    @media (min-width: 528px) {
        flex-direction: row;
      
        div {
            margin: 0 16px 0 0;

            h2 {
              font-size: var(--font-size--36);
              line-height: 41.4px;
            }

            h2 strong {
              display: block;
            }

            button {
              align-self: flex-start;
            }
        }
    }
`;

const StyledModal = styled(Modal)`
    max-width: 330px;
    width: unset;
      
    header {
        display: none;
    }

    @media (min-width: 528px) {
      max-width: 630px !important;
    }
`;

interface ICustomerTypeModal {
    isNationalCustomer: boolean;
    setIsNationalCustomer: (boolean) => void;
}

const CustomerTypeModal: FC<ICustomerTypeModal> = ({
    isNationalCustomer,
    setIsNationalCustomer
}) => {
    const {pathname} = useRouter();
    const isHomepage = pathname === '/deals';

    const {data: store, loading: storeLoading} = useStore();
    const {featureEnabled, loading: featureToggleLoading} = useFeatureToggle();
    const useCustomerTypeModal = featureEnabled(CUSTOMER_TYPE_MODAL);

    if (!featureToggleLoading && !useCustomerTypeModal) {
        setIsNationalCustomer(false);

        return null;
    }

    if (featureToggleLoading || !isHomepage || storeLoading) {
        return null;
    }

    if (store) {
        setIsNationalCustomer(false);

        return null;
    }

    return isNationalCustomer === null && (
        <StyledModal
            size="small"
            style={{maxWidth: '330px'}}
        >
            <StyledContent>
                <div>
                    <img
                        alt="A package by the front door of a house with a hy-veedeals.com logo on the box"
                        data-testid="national-customer-image"
                        src="https://8e3463198116a37cf901-4c8f9ce7667a46d130eda30090f49466.ssl.cf2.rackcdn.com/deals/images/National.jpg"
                    />
                    <h2 data-testid="national-customer-header">
                        {'Deals for '}
                        <strong>{'everyone.'}</strong>
                    </h2>
                    <p>{'Get cash back rebates and shop products shipped directly to you.'}</p>
                    <Button
                        data-testid="national-customer-button"
                        onClick={() => {
                            setIsNationalCustomer(true);
                        }}
                    >
                        {'View Nationwide Deals'}
                    </Button>
                </div>
                <div>
                    <img
                        alt="A Hy-Vee grocery store with a Hy-Vee delivery van parked in front of it"
                        data-testid="local-customer-image"
                        src="https://8e3463198116a37cf901-4c8f9ce7667a46d130eda30090f49466.ssl.cf2.rackcdn.com/deals/images/Local.jpg"
                    />
                    <h2 data-testid="local-customer-header">
                        {'Deals to use '}
                        <strong>{'at Hy-Vee.'}</strong>
                    </h2>
                    <p>{'Browse your local grocery ads, clip digital coupons and more!'}</p>
                    <Button
                        data-testid="local-customer-button"
                        onClick={() => {
                            setIsNationalCustomer(undefined);
                        }}
                    >
                        {'View Local Deals'}
                    </Button>
                </div>
            </StyledContent>
        </StyledModal>
    );
};

export default CustomerTypeModal;
