import {OK} from 'http-status';

import fetchService from './fetch-service';

export const getCustomer = async () => {
    const response = await fetchService('/deals/api/customer');

    if (response.status !== OK) {
        return null;
    }

    return response.json();
};
