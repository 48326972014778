import gql from 'graphql-tag';

const displayFlagsFragment = gql`
    fragment IDisplayFlags on CmsDisplayFlags {
        customerType
        hasFuelSaver
        hasHPlusMembership
        isAuthenticated
    }
`;

export default displayFlagsFragment;
