export const HV_WEEKLY_AD_COOKIE = 'hvWklyAdStoreID';

export const stateOptions = [
    {
        label: 'Illinois',
        value: 'IL'
    },
    {
        label: 'Iowa',
        value: 'IA'
    },
    {
        label: 'Kansas',
        value: 'KS'
    },
    {
        label: 'Minnesota',
        value: 'MN'
    },
    {
        label: 'Missouri',
        value: 'MO'
    },
    {
        label: 'Nebraska',
        value: 'NE'
    },
    {
        label: 'South Dakota',
        value: 'SD'
    },
    {
        label: 'Wisconsin',
        value: 'WI'
    }
];

export const customStyles = {
    menuPortal: (styles, state) => ({
        ...styles,
        zIndex: state.isFocused ? 99991 : 99990
    })
};
