import React, {FC} from 'react';

import {ECOM_GEO_LOCATION_WEB} from '../../../enums/feature-toggle-names';
import {useFeatureToggle} from '../../../contexts/feature-toggle';
import {useCustomerContext} from '../../../contexts/customer-context';

import UnauthenticatedStoreSelectorModal from './unauthenticated-store-selector-modal';
import ReservationStepperModal from './reservation-stepper-modal';

interface IStoreSelectionModal {
    isVisible: boolean;
    onClose: () => void;
}

const StoreSelectionModal: FC<IStoreSelectionModal> = ({isVisible, onClose}) => {
    const {isAuthenticated, loading: customerLoading} = useCustomerContext();
    const {featureEnabled} = useFeatureToggle();
    const isGeolocationEnabled = featureEnabled(ECOM_GEO_LOCATION_WEB);

    if (customerLoading) {
        return null;
    }

    if (!isAuthenticated && isGeolocationEnabled) {
        return (
            <ReservationStepperModal
                isVisible={isVisible}
                onClose={onClose}
            />
        );
    }

    return isAuthenticated ?
        <ReservationStepperModal
            isVisible={isVisible}
            onClose={onClose}
        />
        :
        <UnauthenticatedStoreSelectorModal
            isVisible={isVisible}
            onClose={onClose}
        />;
};

export default StoreSelectionModal;
