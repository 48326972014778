import gql from 'graphql-tag';

import BannerFragment from './BannerFragment';
import CitrusBannerImageSlotFragment from './CitrusBannerImageSlotFragment';
import RgbColorFragment from './RgbColorFragment';

const ImageCarouselFragment = gql`
    ${BannerFragment}
    ${RgbColorFragment}
    ${CitrusBannerImageSlotFragment}

    fragment ImageCarousel on CmsImageCarousel {
        _key
        _type
        banners {
            ...ICmsMarketingBanner
            ...ICmsBannerImageSlot
        }
        sideButtonConfig {
            gradientColor {
                darkColor {
                    ...RgbColorFragment
                }
                lightColor {
                    ...RgbColorFragment
                }
            }
        }
    }
`;

export default ImageCarouselFragment;
