import IErrorWithResponse from '../types/error-with-response';

export default (url: any, options?: any, passedThroughRequest?: any) => {
    const defaultOptions = {
        credentials: 'same-origin',
        headers: {
            'content-type': 'application/json',
            cookie: undefined
        },
        method: 'GET'
    };

    if (passedThroughRequest?.headers?.cookie) {
        defaultOptions.headers.cookie = passedThroughRequest.headers.cookie;
    }

    return fetch(url, {
        ...defaultOptions,
        ...options
    }).then((response) => {
        if (!response.ok) {
            const err: IErrorWithResponse = new Error(`Error fetching ${url}: ${response.status}`);

            err.response = response;
            throw err;
        }

        return response;
    });
};
