import {OK} from 'http-status';

import fetchService from './fetch-service';

const getSelectedAdStorePrivate = async (): Promise<any> => {
    const response = await fetchService('/deals/api/selected-ad-store');

    if (response.status !== OK) {
        return undefined;
    }

    const data = await response.json();

    return data?.storeId;
};

let loadingPromise: Promise<any>;

export const getSelectedAdStore = (): Promise<any> => {
    if (!loadingPromise) {
        loadingPromise = getSelectedAdStorePrivate();

        loadingPromise.finally(() => {
            loadingPromise = undefined;
        });
    }

    return loadingPromise;
};

export const setSelectedAdStore = async (storeId: any): Promise<any> => {
    const response = await fetchService('/deals/api/selected-ad-store', {
        body: JSON.stringify({
            storeId
        }),
        method: 'POST'
    });

    if (response.status !== OK) {
        return undefined;
    }

    const data = await response.json();

    return data?.storeId;
};
