import {MutationActionTypes} from '@hy-vee/global-navigation';

import {graphqlClient} from '../graphql/graphql-client';
import {
    cartItemDeleteCartLookupFragment,
    cartItemQuantityMutateFragment
} from '../../src/graphql/queries/cart-queries';
import {cartForCacheIdentify, cartItemForCacheIdentify} from '../graphql/cache-identify-helpers';
import {GetAislesOnlineCart_carts} from '../autogen/GetAislesOnlineCart';
import {GetStore_stores} from '../autogen/GetStore';

const updateCartAfterQuantityChanged = (cartItemId, quantity) => {
    const client = graphqlClient();

    client.writeFragment({
        data: {
            ...cartItemForCacheIdentify(cartItemId),
            quantity
        },
        fragment: cartItemQuantityMutateFragment
    });
};

const updateCartAfterItemDelete = (cartItemId) => {
    const client = graphqlClient();
    const cartItemCacheId = client.cache.identify(cartItemForCacheIdentify(cartItemId));

    const cachedCartItem = client.readFragment({
        fragment: cartItemDeleteCartLookupFragment,
        id: cartItemCacheId
    });

    if (cachedCartItem) {
        const cachedCartId = client.cache.identify(cartForCacheIdentify(cachedCartItem.cartId));

        client.cache.modify({
            fields: {
                cartItems: (existingCartItemRefs, {readField}) =>
                    existingCartItemRefs.filter((cartItemRef) => readField('cartItemId', cartItemRef) !== cartItemId)
            },
            id: cachedCartId
        });

        client.cache.evict({id: cartItemCacheId});
        client.cache.gc();
    }
};

type IPickupLocation = {__typename: 'pickupLocation'; name: string} | null;

type ICartStore = {__typename: 'store'; legacyStoreId: number; name: string; state: string} | null;

type IDeliveryAddress = {__typename: 'deliveryAddress'; firstName: string};

const storeName = (pickupLocation: IPickupLocation, cartStore: ICartStore | GetStore_stores | undefined): string =>
    cartStore?.name && cartStore.state ? `${pickupLocation?.name ?? cartStore.name}, ${cartStore.state}` : '';

export const getLocationText = (cart: GetAislesOnlineCart_carts, unauthenticatedStore: GetStore_stores) => {
    if (cart) {
        const {fulfillmentType, pickupLocation, deliveryAddress, store: cartStore} = cart;

        if (fulfillmentType === 'PICKUP') {
            return storeName(pickupLocation, cartStore);
        } else if (fulfillmentType === 'PICKUP_POINT') {
            return storeName(pickupLocation, cartStore);
        } else if (fulfillmentType === 'DELIVERY' && deliveryAddress) {
            // eslint-disable-next-line no-extra-parens
            return `${(deliveryAddress as IDeliveryAddress).firstName} - ${deliveryAddress.city}, ${
                deliveryAddress.state
            }`;
        }

        return '';
    }

    return storeName(null, unauthenticatedStore);
};

export const handleDataChange = (action) => {
    const {type: actionType, ...data} = action;

    switch (actionType) {
        case MutationActionTypes.changeCartItemQuantity:
            return updateCartAfterQuantityChanged(data.id, data.quantity);
        case MutationActionTypes.removeCartItem:
            return updateCartAfterItemDelete(data.id);
        default:
            return undefined;
    }
};
