import {isServer} from '../utils/window';

import fetch from './fetch-service';

const deferTimeoutDuration = 2000;

let pageLogsToPost = [];
let deferTimeout;

const postPending = async () => {
    const timeOut = deferTimeout;

    deferTimeout = null;
    clearTimeout(timeOut);

    if (pageLogsToPost.length) {
        const logsToBeSent = pageLogsToPost;

        // Discards despite possibiliy of post error
        pageLogsToPost = [];

        try {
            await fetch('/deals/api/log', {
                body: JSON.stringify(logsToBeSent),
                method: 'POST'
            });
        } catch {}
    }
};

const deferPosts = () => {
    if (!deferTimeout) {
        deferTimeout = setTimeout(postPending, deferTimeoutDuration);
    }
};

const queuePageLog = (pathName: string, mode: string, messages: any[]) => {
    if (isServer()) {
        return;
    }

    pageLogsToPost = [
        ...pageLogsToPost,
        {
            messages,
            mode,
            pathName
        }
    ];

    if (pageLogsToPost.length > 9) {
        postPending();
    } else {
        deferPosts();
    }
};

export const flush = () => {
    if (isServer()) {
        return;
    }

    postPending();
};

export const info = (pathName: string, message: string, ...other: any) => queuePageLog(pathName, 'info', [message, ...other]);

export const error = (pathName: string, clientError: Error, message: string, ...other: any) => queuePageLog(pathName, 'error', [clientError.toString(), message, ...other]);
