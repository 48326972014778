import React, {FC} from 'react';
import {AddToListTypes} from '@hy-vee/react-web-and-mobile-ui-components';

import ID from '../../types/Id';
import {getAddToListUrl} from '../../utils/navigation-helpers';

import IFrameModal from './iframe-modal';
import {IModal} from './types';

type IAddToListModal = IModal & {
    id?: ID
    optionalDealProductId?: ID
    text?: string
    type?: AddToListTypes
}

const AddToListModal: FC<IAddToListModal> = ({isVisible, onClose, id, text, type, optionalDealProductId}) => {
    const handleMessage = (message: string) => {
        if (message === 'close-lists-modal') {
            onClose();
        }
    };

    return (
        <IFrameModal
            ariaLabel="Add or Remove Items from List"
            onMessage={handleMessage}
            showModal={isVisible}
            url={getAddToListUrl(id, type, text, optionalDealProductId)}
        />
    );
};

export default AddToListModal;
