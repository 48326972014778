import React, {FC} from 'react';

import {getShippingPreferenceDrawerUrl} from '../../utils/navigation-helpers';

import IFrameModal from './iframe-modal';

export type IShippingPreferenceModal = {
    isVisible: boolean;
    onClose: () => void;
};

export const ShippingPreferenceModal: FC<IShippingPreferenceModal> = ({isVisible, onClose}) => {
    const iframeUrl = getShippingPreferenceDrawerUrl();

    const handleMessage = (message): void => {
        if (message === 'cancel-shipping-preference-drawer') {
            onClose();
        }
    };

    return (
        <IFrameModal
            ariaLabel="Shipping Preference drawer"
            onMessage={handleMessage}
            showModal={isVisible}
            url={iframeUrl}
        />
    );
};
