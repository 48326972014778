import React from 'react';

export const GTM_TRACKING_ID = 'GTM-5TL68P';

/* eslint-disable react/no-danger */
export const googleTagManagerScriptNonce = (nonce: any) =>
    <script
        dangerouslySetInnerHTML={{
            __html:
                `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;var n=d.querySelector('[nonce]');
n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer', '${GTM_TRACKING_ID}');`
        }}
        data-testid="gtm-init-nonce"
        nonce={nonce}
    />;
/* eslint-enable */
