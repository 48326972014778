import React, {FC, useState, useEffect, useContext} from 'react';

import {
    isServer,
    documentWindow
} from '../utils/window';

interface IScreenContext {
    screenHeight: number;
    screenWidth: number;
}

interface IScreenProviderProps {
    children?: React.ReactNode;
    fixedScreenHeight?: number;
    fixedScreenWidth?: number;
}

const ScreenSizeContext = React.createContext<IScreenContext | undefined>(undefined);
const {Provider, Consumer} = ScreenSizeContext;

ScreenSizeContext.displayName = 'ScreenSizeContext';

export const useScreenSize = () => {
    const context = useContext(ScreenSizeContext);

    if (!context) {
        throw new Error(
            'Unable to screen size context, please ensure your component is within the ScreenSizeProvider.'
        );
    }

    return context;
};

const ScreenSizeProvider: FC<IScreenProviderProps> = ({
    children,
    fixedScreenHeight,
    fixedScreenWidth
}) => {
    const [windowSize, setWindowSize] = useState({
        screenHeight: fixedScreenHeight || 0,
        screenWidth: fixedScreenWidth || 0
    });

    useEffect(() => {
        if (!isServer()) {
            setWindowSize({
                screenHeight: fixedScreenHeight || documentWindow().innerHeight,
                screenWidth: fixedScreenWidth || documentWindow().innerWidth
            });

            documentWindow().addEventListener('resize', () => {
                setWindowSize({
                    screenHeight: fixedScreenHeight || documentWindow().innerHeight,
                    screenWidth: fixedScreenWidth || documentWindow().innerWidth
                });
            });
        }
    }, [fixedScreenHeight, fixedScreenWidth]);

    return (
        <Provider value={windowSize}>
            {children}
        </Provider>
    );
};

export {
    ScreenSizeProvider,
    Consumer as ScreenSize
};
