import React, {useState, useEffect, FC} from 'react';
import styled from 'styled-components';
import {Modal, Button, P} from '@hy-vee/web-core';
import {useRouter} from 'next/router';

import {useStateStore} from '../../../hooks/use-store';
import {setWeeklyAdCookie} from '../../../utils/weekly-ad-cookie';
import SelectWithPlaceholder from '../../select-with-placeholder';
import {customStyles, stateOptions} from '../../../enums/modal-enums';

const CitySelect = styled(SelectWithPlaceholder)`
    margin-top: 10px;
`;

const StyledContent = styled.div`
    overflow: auto;

    @media (max-width: 768px) {
        margin-right: 16px;
    }

`;

const ModalFooter = styled.div`
    padding-top: 20px;
    float: right;
`;

const StyledModal = styled(Modal)`
    max-width: 500px;
    width: unset;
     @media (max-width: 768px) {
        header {
        margin-right: 16px;
        }
        svg {
            margin-right: 3px;
        }
        button  {
            margin-right: 16px;
        }
    }
   
`;

interface IStoreSelectorModal {
    isVisible: boolean;
    onClose: () => void;
}

const UnauthenticatedStoreSelectorModal: FC<IStoreSelectorModal> = ({
    isVisible,
    onClose
}) => {
    const [selectedState, setSelectedState] = useState<string>('');
    const [selectedCity, setSelectedCity] = useState<string>('');
    const [displayStores, setDisplayStores] = useState<boolean>(false);
    const [cityOptions, setCityOptions] = useState([]);
    const [storeOptions, setStoreOptions] = useState([]);
    const router = useRouter();
    const valid = !selectedCity || !selectedState;
    const {data} = useStateStore(selectedState);

    useEffect(() => {
        const cityOptionsTemp: any = [];

        if (data) {
            data.stores.forEach(({city}) => {
                const duplicateCityIndex = cityOptionsTemp.findIndex((x) => x.value === city);

                if (duplicateCityIndex <= -1) {
                    cityOptionsTemp.push({
                        label: city,
                        value: city
                    });
                }
            });
            const alphabeticalCities = cityOptionsTemp.sort((a, b) => a.label > b.label ? 1 : -1);

            setCityOptions(alphabeticalCities);
        }
    }, [selectedState, data]);

    useEffect(() => {
        const storeOptionsTemp: any = [];

        if (data && selectedCity) {
            data.stores.forEach((store) => {
                if (store.city === selectedCity) {
                    const value = `${store.address}, ${store.city}`;

                    storeOptionsTemp.push({
                        label: value,
                        value: store.legacyStoreId
                    });
                }
            });
            setStoreOptions(storeOptionsTemp);
        }
    }, [selectedCity, displayStores]); // eslint-disable-line react-hooks/exhaustive-deps

    const storeSelectedHandler = async (legacyStoreId: string) => {
        await setWeeklyAdCookie(legacyStoreId);
        onClose();
        router.reload();
    };

    const onStateChange = (value: string) => {
        setSelectedState(value);
        setSelectedCity(null);
    };

    return isVisible && (
        <StyledModal
            onClose={onClose}
            size="small"
            title="Choose Your Store"
        >

            <StyledContent>
                {
                    !displayStores ?
                        <>
                            <P>{'Tell us the store you shop at most often so we can find the best deals just for you.'}</P>
                            <SelectWithPlaceholder
                                data-testid="state-select"
                                id="select-container-state"
                                label="State"
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => onStateChange(event.target.value)}
                                options={stateOptions}
                                styles={customStyles}
                                value={selectedState}
                            />
                            <CitySelect
                                data-testid="city-select"
                                disabled={selectedState === ''}
                                id="select-container-city"
                                label="City"
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setSelectedCity(event.target.value)}
                                options={cityOptions}
                                styles={customStyles}
                                value={selectedCity}
                            />
                            <ModalFooter>
                                <Button
                                    data-testid="find-stores"
                                    disabled={valid}
                                    onClick={() => setDisplayStores(true)}
                                >
                                    {'Find Stores'}
                                </Button>
                            </ModalFooter>
                        </>
                        :
                        <SelectWithPlaceholder
                            data-testid="store-select"
                            id="select-container-store"
                            label="Store"
                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => storeSelectedHandler(event.target.value)}
                            options={storeOptions}
                            styles={customStyles}
                            value=""
                        />
                }
            </StyledContent>
        </StyledModal>
    );
};

export default UnauthenticatedStoreSelectorModal;
