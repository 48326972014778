import IErrorWithResponse from '../types/error-with-response';

const graphqlFetch = (url: any, options?: any) =>
    fetch(url, {
        ...options,
        headers: {
            'content-type': 'application/json'
        }
    }).then((response) => {
        if (!response.ok) {
            const err: IErrorWithResponse = new Error(`Error fetching ${url}`);

            err.response = response;
            throw err;
        }

        return response;
    });

export default graphqlFetch;
