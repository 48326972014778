import React, {FC} from 'react';

import {getCouponDetailsUrl} from '../../utils/navigation-helpers';

import IFrameModal from './iframe-modal';
import {IModal} from './types';

type ICouponModal = IModal & {
    couponId: string | number;
}

const CouponModal: FC<ICouponModal> = ({isVisible, onClose, couponId}) => {
    const handleMessage = (message: string) => {
        if (message === 'close-coupon-modal') {
            onClose();
        }
    };

    return (
        <IFrameModal
            ariaLabel="View Coupon Details"
            onMessage={handleMessage}
            showModal={isVisible}
            url={getCouponDetailsUrl(couponId)}
        />
    );
};

export default CouponModal;
