import {deleteWeeklyAdPreviewDate, getWeeklyAdPreviewDate} from '../services/weekly-ad-preview-date-service';

import {isServer} from './window';

export const getWeeklyAdPreviewDateCookie = () => {
    try {
        if (!isServer()) {
            return getWeeklyAdPreviewDate();
        }
    } catch {}

    return undefined;
};

export const deleteWeeklyAdPreviewDateCookie = async () => {
    try {
        if (!isServer()) {
            await deleteWeeklyAdPreviewDate();
        }
    } catch {}
};

