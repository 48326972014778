import gql from 'graphql-tag';

import cmsTextBlock from './TextBlockFragment';
import colorFragment from './ColorFragment';

const cmsMarketingBannerImageFragment = gql`
  ${cmsTextBlock}
    fragment ICmsMarketingBannerImage on CmsMarketingBannerImage {
        alt
        asset {
            metadata {
                dimensions {
                    height
                    width
                }
            }
            url
        }
        overlays {
            _key
            left
            top
            body {
                ... on CmsButton {
                    _key
                    _type
                    buttonText
                    href
                    disabled
                    testID
                }
                ... on CmsOverlayProductButton {
                    _key
                    _type
                    productId
                }
                ...ITextBlock
            }
        }
    }
`;

const marketingBannerFragment = gql`
    ${cmsMarketingBannerImageFragment}
    ${colorFragment}
    fragment ICmsMarketingBanner on CmsMarketingBanner {
        _type
        _key
        href
        name
        webImage {
        ...ICmsMarketingBannerImage
        }
        tabletImage {
        ...ICmsMarketingBannerImage
        }
        mobileImage {
        ...ICmsMarketingBannerImage
        }
        bgColor {
            ...IColor
        }
        secondaryBgColor {
            ...IColor
        }
    }
`;

export default marketingBannerFragment;
