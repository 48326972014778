import {NextWebVitalsMetric} from 'next/app';
import getConfig from 'next/config';

import fetch from '../services/fetch-service';

const vitalsMap = Object.freeze(
    new Map<string, [number, number, string]>([
        ['FCP', [1800, 3000, 'First Contentful Paint (FCP)']],
        ['LCP', [2500, 4000, 'Largest Contentful Paint (LCP)']],
        ['CLS', [0.1, 0.25, 'Cumulative Layout Shift (CLS)']],
        ['FID', [100, 300, 'First Input Delay (FID)']],
        ['TTFB', [300, 500, 'Time to First Byte (TTFB)']]
    ])
);

export interface ICoreWebDashboardPayload {
    metric: NextWebVitalsMetric;
    origin: string;
    path: string;
    query?: string;
}

export type LogColors = 'green' | 'orange' | 'red';

const verboseLoggingEnabled = () => {
    return getConfig().publicRuntimeConfig.vitalsVerboseLogging;
};

const log = (color: LogColors, value: number, name: string) => {
    console.log(`${name}:%c ${value}`, `color: ${color}`);
};

export const logWebVitals = (metric: NextWebVitalsMetric) => {
    if (metric.label === 'web-vital') {
        const verboseLogging = verboseLoggingEnabled();

        fetch('/deals/api/web-vitals', {
            body: JSON.stringify({
                metric: {
                    stat: metric.value,
                    vital: metric.name
                },
                referrer: window.location.href
            }),
            method: 'POST'
        }).catch((error: any) => {
            if (verboseLogging) {
                console.error(error);
            }
        });

        if (verboseLogging) {
            const {name, value} = metric;

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const [goodValue, badValue, logName] = vitalsMap.get(name)!;
            let color: LogColors = 'orange';

            if (value <= goodValue) {
                color = 'green';
            } else if (value > badValue) {
                color = 'red';
            }

            log(color, value, logName);
        }
    }
};
