import {useMemo} from 'react';
import {useQuery} from '@apollo/client';
import {getNearestStoreFromCookie} from '@hy-vee/user-location';

import {getStore} from '../../src/graphql/queries/navigation-queries';
import {TWO_LEGGED_AUTH_TYPE} from '../enums/auth-types';
import {setWeeklyAdCookie} from '../utils/weekly-ad-cookie';
import {GetStore, GetStoreVariables, GetStore_stores} from '../autogen/GetStore';
import IApolloQuery from '../types/apollo-query';
import {storeInput} from '../autogen/globalTypes';
import {useCookies} from '../contexts/cookie-context';
import {useCustomerContext} from '../contexts/customer-context';
import {ECOM_GEO_LOCATION_WEB} from '../enums/feature-toggle-names';
import {useFeatureToggle} from '../contexts/feature-toggle';
import {isServerSide} from '../utils/env';

import {useAislesOnlineCart} from './use-cart';

interface IUseStore {
    data: GetStore_stores | undefined;
    loading: boolean;
}

interface IUseStateStore {
    data: GetStore | undefined;
    loading: boolean;
}

const useStoreQuery = (where: storeInput, skip: boolean) =>
    useQuery<GetStore, IApolloQuery<GetStoreVariables>>(getStore, {
        fetchPolicy: 'cache-first',
        skip,
        ssr: false,
        variables: {
            authType: TWO_LEGGED_AUTH_TYPE,
            storesWhere: where
        }
    });

export const useStore = (): IUseStore => {
    const {isAuthenticated} = useCustomerContext();
    const {featureEnabled} = useFeatureToggle();
    const isGeolocationEnabled = featureEnabled(ECOM_GEO_LOCATION_WEB);
    const {cookieStoreId, loading: cookieStoreIdLoading} = useCookies();
    const {data: cartData, loading: cartLoading} = useAislesOnlineCart();
    const geolocationStore = getNearestStoreFromCookie();
    const isGeolocationStoreQuery = !isServerSide() && !isAuthenticated && isGeolocationEnabled && Boolean(geolocationStore);

    const storeId = useMemo(() => {
        if (isGeolocationStoreQuery) {
            return geolocationStore.storeId;
        }

        if (!cartLoading && !cookieStoreIdLoading) {
            const newStoreId = cartData?.store?.legacyStoreId?.toString() || cookieStoreId;

            if (newStoreId) {
                if (newStoreId !== cookieStoreId) {
                    setWeeklyAdCookie(newStoreId);
                }

                return newStoreId;
            }
        }

        return undefined;
    }, [cartData?.store?.legacyStoreId, cartLoading, cookieStoreIdLoading, cookieStoreId, isGeolocationStoreQuery, geolocationStore]);

    const skipStoreQuery = !storeId || cartLoading || cookieStoreIdLoading;
    const whereInput = isGeolocationStoreQuery ?
        {
            zip: geolocationStore.zip
        } :
        {
            legacyStoreId: Number(storeId)
        };

    const {data, loading: storeQueryLoading} = useStoreQuery(whereInput, skipStoreQuery);

    return {
        data: data?.stores?.[0],
        loading: !skipStoreQuery && storeQueryLoading || cartLoading || cookieStoreIdLoading
    };
};

export const useStateStore = (state: any): IUseStateStore => {
    const {data, loading} = useStoreQuery({state}, !state);

    return {
        data,
        loading
    };
};
